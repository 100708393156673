import { API_URL } from './api';
import ApiService from './ApiService';

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: `${API_URL}auth/jwt/create/`,
    method: 'post',
    data
  });
}

export async function reset_password(data) {
  return ApiService.fetchData({
    url: `${API_URL}auth/users/reset_password_confirm/`,
    method: 'post',
    data
  });
}

export async function getUsers(params) {
  return ApiService.fetchData({
    url: `${API_URL}auth/users/`,
    method: 'get',
    params
  });
}

export async function getLoggedInUser() {
  return ApiService.fetchData({
    url: `${API_URL}auth/users/me/`,
    method: 'get',
    params: {
      expand: 'admins.university,teams,university'
    }
  });
}

export async function updateUser(data) {
  return ApiService.fetchData({
    url: `${API_URL}auth/users/${data.id}/`,
    method: 'PATCH',
    data
  });
}

export async function patchUser(data) {
  return ApiService.fetchData({
    url: `${API_URL}auth/users/${data.id}/`,
    method: 'PATCH',
    params: {
      expand: 'teams'
    },
    data
  });
}

export async function uploadProfile(data) {
  return ApiService.fetchData({
    url: `${API_URL}auth/users/${data.userId}/`,
    method: 'PATCH',
    data: data.form,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export async function resetPassword(data) {
  return ApiService.fetchData({
    url: `${API_URL}auth/users/set_password/`,
    method: 'POST',
    data
  });
}

export async function forgetPassword(data) {
  return ApiService.fetchData({
    url: `${API_URL}auth/users/reset_password/`,
    method: 'POST',
    data
  });
}

export async function refreshToken(data) {
  return ApiService.fetchData({
    url: `${API_URL}auth/jwt/refresh/`,
    method: 'POST',
    data
  });
}
