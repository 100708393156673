import React, {
  createContext,
  useState,
  useEffect,
  useContext
} from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('dark-mode');
    if (savedTheme === 'true') setIsDarkMode(true);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      'dark-mode',
      isDarkMode.toString()
    );
    const htmlElement = document.querySelector('html');
    if (isDarkMode) {
      htmlElement.classList.add('dark');
      htmlElement.classList.add('sl-theme-dark');
    } else {
      htmlElement.classList.remove('dark');
      htmlElement.classList.remove('sl-theme-dark');
    }
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ isDarkMode, toggleDarkMode }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
