/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  nickname: '',
  email: '',
  id: '',
  gener: 'u',
  first_name: '',
  last_name: '',
  phone: '',
  country: '',
  state: '',
  address: '',
  zip_code: '',
  data_of_birth: '',
  date_joined: '',
  last_login: '',
  university: '',
  picture: '',
  has_accepted_toc: true,
  has_accepted_survey: false,
  type: '',
  teams: null,
  admins: null
};

const userSlice = createSlice({
  name: `auth/user`,
  initialState,
  reducers: {
    setUser(state, action) {
      state.token = action.payload?.token;
      state.email = action.payload?.email;
      state.id = action.payload?.id;
      state.gener = action.payload?.gener;
      state.first_name = action.payload?.first_name;
      state.last_name = action.payload?.last_name;
      state.phone = action.payload?.phone;
      state.country = action.payload?.country;
      state.state = action.payload?.state;
      state.address = action.payload?.address;
      state.zip_code = action.payload?.zip_code;
      state.data_of_birth = action.payload?.data_of_birth;
      state.date_joined = action.payload?.date_joined;
      state.last_login = action.payload?.last_login;
      state.university = action.payload?.university;
      state.nickname = action.payload?.nickname;
      state.picture = action.payload?.picture;
      state.has_accepted_toc =
        action.payload?.has_accepted_toc;
      state.has_accepted_survey =
        action.payload?.has_accepted_survey;
      state.type = action.payload?.type;
      state.teams = action.payload?.teams;
      state.admins = action.payload?.admins;
    }
  }
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
